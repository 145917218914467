import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';

import ContactForm from '../../../components/forms/ContactForm';

import HeroH2 from '../../../components/hero/HeroH2';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H3 from '@rotaready/frecl/build/H3';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import Margin from '@rotaready/frecl/build/Margin';

const isBrowser = typeof window !== 'undefined';

const HeroWrapper = styled.div`
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: right top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 130px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 45%;
    margin: 0;
  }
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
`;

const TileTagContainer = styled.div`
  margin-bottom: 20px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.formTopRef = React.createRef();
  }

  goToForm = () => {
    const scrollTo = this.formTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Key dates for hospitality - 2023/2024"
          description="For the third year running, we've updated our hospitality calendar with all the important dates in 2023."
          url="resources/hospitality-calendar-2023"
        />

        <HeroWrapper
          backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
          backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
        >
          <ResponsiveContainer>
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <TileTagContainer>
                    <TileTag text="Hospitality" />
                  </TileTagContainer>

                  <HeroH2 color="brand160" text="Key dates for hospitality - 2023/2024" />

                  <Margin marginTop={40}>
                    <div>
                      <Button uistyle="primary" text="Download now" size="lg" onClick={this.goToForm} />
                    </div>
                  </Margin>
                </HeroBody>
              </HeroInnerContainer>
            </Hero>
          </ResponsiveContainer>
        </HeroWrapper>

        <ResponsiveContainer>
          <BodyWrapper>
            <BodyItem>
              <TextBlock>
                <H3 uistyle="brand160" text="2023 brings a whole new year of events, each having a different effect on the footfall to your venue." />
              </TextBlock>

              <TextBlock>
                <Text size="xl" text="Our event calendar has been updated for the third year running. It includes all the major UK events for this calendar year, and into the early part of next year too. With this insight to hand, staffing your venue to meet anticipated demand becomes a little bit easier." />
              </TextBlock>
            </BodyItem>

            <BodyItem innerRef={this.formTopRef}>
              <ContactForm
                title=""
                submitLabel="Download now"
                source="resources_hospitality_calendar_2023"
                label="rotaready.com/resources/hospitality-calendar-2023"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Enjoy 🗓" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading our 2023/2024 Hospitality Calendar." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/Rotaready-Hospitality-Calendar-2023.pdf" download>
                        <Button uistyle="primary" text="View calendar" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </BodyItem>
          </BodyWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "resources/hospitality-calendar-2023/hero-desktop.png" }) {
      childImageSharp {
        fixed(height: 1400, width: 1512, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "resources/hospitality-calendar-2023/hero-mobile.png" }) {
      childImageSharp {
        fixed(height: 894, width: 750, quality: 30) {
          src
        }
      }
    }
  }
`;
